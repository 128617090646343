import styles from './NewAppointmentFormPage.module.scss'

// Components
import FormStepOne from '../../components/NewForm/Step1/StepOne'

// React
import { useState, useEffect } from 'react'

// Functions
// import { addDataFirestore } from '../../firebase/post'
import AlertMessage from '../../components/AlertMessage/AlertMessage'
import { useAuthValue } from '../../context/AuthContext'
import { useInsertDocument } from '../../hooks/useInsertDocument'
import { useFetchDocuments } from '../../hooks/useFetchDocuments';
import { useFetchEnabledHours } from '../../hooks/useFetchEnabledHours';
import { useHours } from '../../hooks/useHours'
import { useAdminValue } from '../../context/AdminContext'
import { useDays } from '../../hooks/useDays'
import StepTwo from '../../components/NewForm/Step2/StepTwo'
import StepThree from '../../components/NewForm/Step3/StepThree'
import StepFour from '../../components/NewForm/Step4/StepFour'
import StepFive from '../../components/NewForm/Step5/StepFive'
import StepSix from '../../components/NewForm/Step6/StepSix'
import ConcludedForm from '../../components/ConcludedForm/ConcludedForm'
import { AnimatePresence } from 'framer-motion'

const uuid = require('uuid');

const barbers = ["Carlos", "Donizete"]

const NewAppointmentFormPage = () => {

    const [name, setName] = useState("");
    const [cel, setCel] = useState("");
    const [date, setDate] = useState("");
    const [hour, setHour] = useState([]);
    const [service, setService] = useState("");
    const [professional, setProfessional] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [appointmentHours, setAppointmentHours] = useState("");
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [hoursIndex, setHoursIndex] = useState();
    const [noDayOffBarber, setNoDayOffBarber] = useState();
    const [exclusiveHours, setExclusiveHours] = useState();
    const { isAdmin } = useAdminValue();
    const { hours } = useHours(date);
    const [step, setStep] = useState(1)

    const { formatDate, dayPlusSeven } = useDays();
    const todaysDate = formatDate(0);
    const todaysDatePlusSeven = dayPlusSeven(7);

    const handleDateChange = (e) => {
        const realDate = e.target.value;
        const date = realDate.split("-");
        const dateObject = {
            day: Number(date[2]),
            month: Number(date[1]),
            year: Number(date[0])
        };

        const checkSunday = new Date(dateObject.year, dateObject.month - 1, dateObject.day);

        if (checkSunday.getDay() === 0) {
            setFormError(true);
            setFormErrorMessage("Não atendemos aos domingos. Escolha outra data.")
        } else {
            setDate(realDate);
        }

        const dayOffBarberObj = dayOffs.filter(e => e.date === realDate) || undefined

        if (dayOffBarberObj.length > 0) {
            const barber = dayOffBarberObj[0].professional

            const working = barbers.filter((e) => !barber.includes(e))

            setNoDayOffBarber(working)

            setFormError(true);
            setFormErrorMessage(`O Barbeiro ${barber} estará de folga no dia ${new Date(realDate + 'T00:00:00').toLocaleDateString()}`)
        } else {
            setNoDayOffBarber(barbers)
        }

        setService('')
        setProfessional('')
        setHour('')

    }
    // Custom Hooks
    const { user: authUser } = useAuthValue();

    const { setDocument } = useInsertDocument('transactions');
    const {
        documents,
        loading: loadingUserData,
        error
    } = useFetchDocuments("users", authUser ? authUser.uid : null);
    const {
        documents: enabledHours,
        // error: errorEnabledHours
    } = useFetchEnabledHours("transactions", professional, date);
    const {
        documents: dayOffs,
    } = useFetchDocuments("day-off");

    const uid = authUser ? authUser.uid : null;

    const handleCelChange = (e) => {

        const target = e.target.value.replace(/\(|\)|-| /g, '');

        if (target.match(/[a-zA-Z]/)) {
            return;
        }
        if (target.length < 12) {
            setCel(e.target.value);
        } else {
            return;
        }

        let celArray = target.split('');

        if (e.target.value.length === 11) {
            celArray.splice(0, 0, '(')
            celArray.splice(3, 0, ')')
            celArray.splice(4, 0, ' ')
            celArray.splice(10, 0, '-')
            let cel = celArray.join('')
            setCel(cel)
        }
    }

    // Handle submit form function
    const handleSubmit = (e) => {

        (async () => {
            e.preventDefault();
            if (!navigator.onLine) {
                setFormError(true)
                setFormErrorMessage("Sem Internet! Atualize a página.")
                setAppointmentHours([])
                setExclusiveHours([])
            }
            else {
                const tid = uuid.v4()
                const uid = authUser ? authUser.uid : null
                setLoading(true);

                const appointment = {
                    name,
                    cel,
                    date,
                    hour,
                    tid,
                    professional,
                    service,
                    uid,
                    hours_index: hoursIndex
                }

                if (date === '' || professional === '' || hour.length === 0 || service === '' || name === '' || cel === '') {
                    setFormError(true)
                    setFormErrorMessage('Alguma informação não foi preenchida, revise o agendamento.')
                } else {
                    setDocument(appointment, tid)
                    setFormSubmitted(true);
                    setLoading(false);
                    setTransactionId(tid);
                    setProfessional('');
                    setStep(7)
                }

            }
        })()

    };

    useEffect(() => {
        localStorage.setItem('transactionId', transactionId)
    }, [transactionId])

    useEffect(() => {
        if (!isAdmin) {
            if (uid !== null && documents !== null) {
                documents.forEach(e => {
                    setName(e.name);
                    setCel(e.cellphone);
                })
            }
        }
    }, [uid, documents, isAdmin]);

    useEffect(() => {
        if (uid !== null && !isAdmin) {
            setStep(2)
        }
    }, [uid])

    useEffect(() => {
        if (error) {
            setFormError(true)
            setFormErrorMessage("Sem Internet! Atualize a página.")
        }
    }, [error])

    useEffect(() => {
        if (date && professional && enabledHours) {


            const handleEnabledHours = async () => {
                if (!navigator.onLine) {
                    setFormError(true)
                    setFormErrorMessage("Sem Internet! Atualize a página.")
                    setAppointmentHours([])
                    setExclusiveHours([])
                } else {
                    let data = enabledHours;

                    let apt = data
                        .filter(e => e.date === date)
                        .filter(e => e.professional === professional)
                        .map(e => e.hour)

                    let hoursFormatted = JSON.stringify(apt)
                        .replace(/\[|\]/g, '')
                        .replace(/(['"])/g, '')
                        .split(',');


                    if (new Date(date + "T00:00:00").getDay() === 6) {
                        hoursFormatted.push("18:40")
                    } else {
                        hoursFormatted.push("20:00")
                    }

                    console.log("hoursFormatted")
                    console.log(hoursFormatted)

                    setAppointmentHours(hoursFormatted);
                }
            }
            handleEnabledHours();
        }
    }, [date, professional, enabledHours]);

    useEffect(() => {

        if (hours) {
            let hoursFiltered = hours.filter(ele => !appointmentHours.includes(ele))

            const dateParsed = new Date(date + 'T00:00:00').toLocaleDateString();

            const dateNow = new Date();

            if (dateNow.toLocaleDateString() === dateParsed) {

                if (!navigator.onLine) {
                    hoursFiltered = [];
                } else {
                    const hourNow = new Date().getTime()

                    hoursFiltered = hoursFiltered.filter((ele) => {
                        console.log('entrou aqui')
                        let received;

                        const date = new Date()

                        const dateOffset = date.getTimezoneOffset();
                        let dateTimestamp = date.getTime()

                        dateTimestamp = dateTimestamp - dateOffset * 60 * 1000

                        const dateNowJson = new Date(dateTimestamp).toJSON().split("T")[0]
                        const dateHourMounted = new Date(`${dateNowJson}T${ele}:00`).getTime() //- (600000 * 6 * 24); //.358Z

                        if (hourNow <= dateHourMounted - (600000)) { //+(600000 * 6 * 24)
                            received = ele
                        } else {
                            console.log('nao é')
                        }
                        console.log(received)
                        return received;
                    })
                }

                // hoursFiltered = hoursFiltered.filter(ele => {
                //     const elemReplaced = parseInt(ele.replace(':', ''));
                //     let received;
                //     if (elemReplaced > hourNowRefact) {
                //         received = ele;
                //     }
                //     return received;
                // })

                if (hoursFiltered.length === 0) {
                    // setProfessional('')
                    setFormError(true)
                    setFormErrorMessage('Não temos horários com este barbeiro.')
                }
                setExclusiveHours(hoursFiltered)
            } else if (!navigator.onLine) {
                setExclusiveHours([]);
            } else {
                if (hoursFiltered.length === 0) {
                    // setProfessional('')
                    setFormError(true)
                    setFormErrorMessage('Não temos horários com este barbeiro.')
                }
                setExclusiveHours(hoursFiltered);
            }
        }

    }, [appointmentHours, hours, date])

    return (
        <div className={styles.container}>
            {formError &&
                <AlertMessage
                    setFormError={setFormError}
                    formErrorMessage={formErrorMessage}
                />
            }
            <AnimatePresence wait>
                {(!authUser || isAdmin) && step === 1 &&
                    <FormStepOne
                        key='step-one'
                        name={name}
                        setName={setName}
                        cel={cel}
                        setCel={setCel}
                        step={step}
                        setStep={setStep}
                        handleCelChange={handleCelChange}
                    />
                }
                {step === 2 &&
                    <StepTwo
                        key='step-two'
                        todaysDate={todaysDate}
                        todaysDatePlusSeven={todaysDatePlusSeven}
                        date={date}
                        setDate={setDate}
                        handleDateChange={handleDateChange}
                        step={step}
                        setStep={setStep}
                        setFormError={setFormError}
                        setFormErrorMessage={setFormErrorMessage}
                        setHour={setHour}
                        authUser={authUser}
                        isAdmin={isAdmin}
                    />
                }
                {step === 3 &&
                    <StepThree
                        key='step-three'
                        setStep={setStep}
                        service={service}
                        setService={setService}
                        setFormError={setFormError}
                        setFormErrorMessage={setFormErrorMessage} />
                }
                {step === 4 &&
                    <StepFour
                        key='step-four'
                        setStep={setStep}
                        professional={professional}
                        setProfessional={setProfessional}
                        exclusiveHours={exclusiveHours}
                        setExclusiveHours={setExclusiveHours}
                        setFormError={setFormError}
                        setFormErrorMessage={setFormErrorMessage}
                    />
                }
                {step === 5 &&
                    <StepFive
                        key='step-five'
                        setStep={setStep}
                        exclusiveHours={exclusiveHours}
                        hour={hour}
                        setHour={setHour}
                        service={service}
                        hours={hours}
                        appointmentHours={appointmentHours}
                        setFormError={setFormError}
                        setHoursIndex={setHoursIndex}
                        setFormErrorMessage={setFormErrorMessage}
                    />
                }
                {step === 6 &&
                    <StepSix
                        key='step-six'
                        name={name}
                        cel={cel}
                        service={service}
                        professional={professional}
                        hour={hour}
                        date={date}
                        setStep={setStep}
                        handleSubmit={handleSubmit}
                    />
                }
                {step === 7 &&
                    <ConcludedForm
                        transactionId={transactionId}
                        hour={hour}
                        date={date}
                        name={name} 
                        professional={professional}
                        service={service}
                        cel={cel}
                    />
                }
            </AnimatePresence>
        </div>
    )
}

export default NewAppointmentFormPage