import React from 'react';
import styles from './StepOne.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

const FormStepOne = ({ name, setName, cel, setCel, step, setStep, handleCelChange }) => {
  return (
    <AnimatePresence wait>
        <motion.div className={styles.container}
          key="step-one"
          initial={{ x: '200vw', opacity: 0 }}
          animate={{  x: 0, opacity: 1 }}
          exit={{  opacity: 0 }}
          transition={{ type: "spring", stiffness: 50 }}
          // style={{ position: 'absolute', width: '100%', height: '100%'}}
          // transition={{ duration: 5 }}
        >
          <form
            className={styles["appointment-form"]}
            onSubmit={(e) => {
              e.preventDefault();
              setStep(2);
              console.log(name, cel);
            }}
          >
            <label>
              <span>Nome Completo</span>
              <input
                className={styles.inputText}
                type="text"
                name="nome"
                onChange={(e) => setName(e.target.value)}
                placeholder="Digite seu nome completo"
                value={name}
                required
              />
            </label>
            <label>
              <span>Celular</span>
              <input
                type="text"
                name="celular"
                placeholder="(00) 90000-0000"
                onChange={(e) => handleCelChange(e)}
                value={cel}
                required
              />
            </label>
            <input type="submit" value="Selecionar a data" />
          </form>
        </motion.div>
     </AnimatePresence>

  );
};

export default FormStepOne;