import { useEffect, useState } from "react"

export const useWeek = () => {
  const [daysOfTheWeek, setDaysOfTheWeek] = useState([])
  
  useEffect(() => {
    
    let weekArray = []
    
    for (let i = 0; i < 8; i++) {

      const actualDate = new Date()
      actualDate.setDate(actualDate.getDate() + i)

      if(actualDate.getDay() === 0) {
        continue;

      } else {
        const formattedDate = actualDate.toLocaleDateString('pt-BR', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }); 
        // weekArray = []
        weekArray.push({formattedDate, actualDate})
      }
    }
    setDaysOfTheWeek(weekArray)
  }, [])

  return {daysOfTheWeek}
}

