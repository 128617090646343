import styles from './StepSix.module.scss';
import { AnimatePresence, motion } from 'framer-motion'

const StepSix = ({ name, cel, service, professional, hour, date, setStep, handleSubmit }) => {
  const handleClick = () => {
    setStep(5);
  };

  return (
    <AnimatePresence wait>
      <motion.div
        className={styles.container}
        key="step-six"
        initial={{ x: '200vw', opacity: 0 }}
        animate={{  x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      // transition={{ duration: 0.5 }}
      >
        <button onClick={handleClick} className={styles.backButton}>Voltar</button>
        <div className={styles.details}>
          <h4>Nome</h4>
          <p>{name}</p>
          <h4>Celular</h4>
          <p>{cel}</p>
          <h4>Data</h4>
          <p>{new Date(date + 'T00:00:00').toLocaleDateString()}</p>
          <h4>Barbeiro</h4>
          <p>{professional}</p>
          <h4>Serviço</h4>
          <p>{service}</p>
          <h4>Horário</h4>
          <p>{hour[0]}</p>
        </div>
        <button onClick={handleSubmit} className={styles.submitButton}>Agendar</button>
      </motion.div>
    </AnimatePresence>
  );
};

export default StepSix;
