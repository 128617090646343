import { useState } from 'react'
import styles from './StepThree.module.scss'
import { AnimatePresence, motion } from 'framer-motion';

const StepThree = ({ setStep, step, service, setService, setFormError, setFormErrorMessage }) => {

  const [activeIndex, setActiveIndex] = useState("");

  const handleClick = (e, i) => {
    console.log('servico')
    console.log(e)
    setActiveIndex(i)
    setService(e)
    setStep(4)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (service === '') {
      setFormError(true)
      setFormErrorMessage('Escolha um serviço')
    } else {
      setStep(4)
    }
  }

  const services = ['Cabelo', 'Barba', 'Cabelo e Barba']

  return (
    <AnimatePresence wait>
      <motion.div
        className={styles.container}
        key="step-three"
        initial={{ x: '200vw', opacity: 0 }}
        animate={{  x: 0, opacity: 1 }}
        exit={{  opacity: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      // transition={{ duration: 0.5 }}
      >
        <button onClick={() => setStep(2)} className={styles.backButton}>Voltar</button>
        <form onSubmit={(e) => handleSubmit(e)} className={styles.formStepThree}>
          <div className={styles.dateContainer}>
            <label>
              <span>Escolha um serviço</span>
              {service !== '' &&
                services.map((e, i) => (
                  <div key={i} className={service === e ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e} >{e}</div>
                ))}
              {service === '' &&
                services.map((e, i) => (
                  <div key={i} className={i === activeIndex ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e} >{e}</div>
                ))}
            </label>
          </div>
          {/* <input type="submit" value='Agendar' /> */}
        </form>
      </motion.div>
    </AnimatePresence>
  )
}

export default StepThree