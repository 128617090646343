import { useEffect, useState } from 'react'
import styles from './StepTwo.module.scss'
import { useWeek } from '../../../hooks/useWeek'
import { motion, AnimatePresence } from 'framer-motion'

const StepTwo = ({ date, setDate, handleDateChange, step, setStep, setFormError, setFormErrorMessage, setHour, authUser, isAdmin }) => {

  const [activeIndex, setActiveIndex] = useState()
  const { daysOfTheWeek } = useWeek()

  const handleClick = (e, i) => {
    setActiveIndex(i)
    setHour('')
    setDate(new Date(e.actualDate).toJSON().split('T')[0])
    // if (date === '') {
    //   setFormError(true)
    //   setFormErrorMessage('Selecione uma data')
    // } else {
    setStep(3)
    // }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (date === '') {
      setFormError(true)
      setFormErrorMessage('Selecione uma data')
    } else {
      setStep(3)
    }
  }

  return (
    <AnimatePresence wait>
      <motion.div
        className={styles.container}
        key="step-two"
        initial={{ x: '200vw', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      // transition={{ duration: 0.5 }}
      >
        {(!authUser || isAdmin) &&
          <div className={styles.buttonContainer}>
            <button onClick={() => setStep(1)} className={styles.backButton}>Voltar</button>
          </div>
        }
        <form onSubmit={(e) => handleSubmit(e)} className={styles.formStepTwo}>
          <div className={styles.dateContainer}>
            <label>
              <span>Escolha uma data</span>
              {date !== '' &&
                daysOfTheWeek.map((e, i) => (
                  <div key={i} className={date === new Date(e.actualDate).toJSON().split('T')[0] ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e.actualDate} >{e.formattedDate}</div>
                ))}
              {date === '' &&
                daysOfTheWeek.map((e, i) => (
                  <div key={i} className={i === activeIndex ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e.actualDate} >{e.formattedDate}</div>
                ))}
            </label>
          </div>
          {/* <input type="submit" value='Selecionar Horário' /> */}
        </form>
      </motion.div>
    </AnimatePresence>
  )
}

export default StepTwo