import React from 'react'
import { motion } from 'framer-motion'
import styles from './teste.module.scss'

const Teste = () => {

  const handleSubmit = (e) => {
    e.preventDefault()

    console.log("Testando")

    console.log(e.target.getAttributeNames())

    e.target.querySelector('input').setAttribute('disabled', '')
  }
  return (
    <div>
      <motion.div
        className={styles.box}
        animate={{
          scale: [1, 2, 2, 1, 1],
          rotate: [0, 0, 180, 180, 0],
          borderRadius: ["0%", "0%", "50%", "50%", "0%"]
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 1
        }}
      />
    </div>
  )
}

export default Teste