import { useAuthValue } from '../../context/AuthContext'
import styles from './concluded-form.module.scss'
import { Link } from 'react-router-dom'

export default function Concluido({ transactionId, hour, date, name, professional, service, cel }) {

    date = date.split('-')
    const { user } = useAuthValue();


    return (
        <div
        className={styles.container}>


            <div className={styles.details}>
                <h4>Nome</h4>
                <p>{name}</p>
                <h4>Celular</h4>
                <p>{cel}</p>
                <h4>Data</h4>
                <p>{new Date(date + 'T00:00:00').toLocaleDateString()}</p>
                <h4>Barbeiro</h4>
                <p>{professional}</p>
                <h4>Serviço</h4>
                <p>{service}</p>
                <h4>Horário</h4>
                <p>{hour[0]}</p>
                <h4>ID do agendamento</h4>
                <p>{transactionId}</p>
                <div className={styles['div_link']}>
                    <Link to={'/cancel-appointment/' + transactionId} >Link de cancelamento</Link>
                </div>
            </div>
            {/* <div className={styles['concluded-container']} id='appointmentConfirmation'>
                <section className={styles["sectionId"]}>
                    <h3>
                        Agendamento realizado!
                    </h3>
                    <section className={styles['info_sect']}>
                        <p> <span>Foi agendado na data:</span><span className={styles["black"]}>{date[2]}/{date[1]}/{date[0]}</span></p>
                        <p> <span>Horário:</span><span className={styles["black"]}>{hour[0]}</span></p>
                        <p className='show-id'> <span>Seu ID do atendimento é:</span><span className={styles["black"]}>{transactionId}</span></p>
                    </section>
                    <div className={styles['div_link']}>
                        <Link to={'/cancel-appointment/' + transactionId} >Link de cancelamento</Link>
                    </div>
                </section>


            </div> */}
            {!user &&
                <div className={styles["notice"]}>
                    <h3>Salve o link para cancelar o agendamento.</h3>
                    <p>Você pode cancelar em até 5 horas antes do horário marcado,</p>
                    <p>Caso precise depois deste tempo, entre em contato pelo WhatsApp!</p>
                </div>}
            {user &&
                <div className={styles["notice"]}>
                    <h3>Salve o link para cancelar o agendamento ou cancele em seu perfil.</h3>
                    <p>Você pode cancelar em até 5 horas antes do horário marcado,</p>
                    <p>Caso precise depois deste tempo, entre em contato pelo WhatsApp!</p>
                </div>}
        </div>
    )
}