import { useState } from 'react';
import styles from './StepFour.module.scss'
import { AnimatePresence, motion } from 'framer-motion';

const StepFour = ({ step, setStep, professional, setProfessional, exclusiveHours, setExclusiveHours, setFormError, setFormErrorMessage }) => {
  const [activeIndex, setActiveIndex] = useState('');

  const handleClick = (e, i) => {
    if (exclusiveHours && exclusiveHours.length === 0) {
      setActiveIndex('')
      setProfessional('')
      setExclusiveHours(undefined)
    }
    else 
    if (exclusiveHours && exclusiveHours.length > 0) {
      setActiveIndex(i)
      setProfessional(e)
      setStep(5)
    } else {
      setFormError(true)
      setFormErrorMessage('Sem horários')
      setExclusiveHours('')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (professional === '') {
      setFormError(true)
      setFormErrorMessage('Escolha um profissional')
    } else {
      if (exclusiveHours.length === 0) {
        setActiveIndex('')
        setProfessional('')
        return
      } else {
        setStep(5)
      }
    }
  }

  const handleBack = () => {
    setStep(3)
  }

  const barbers = ['Carlos', 'Donizete']

  return (
    <AnimatePresence wait>
      <motion.div
        className={styles.container}
        key="step-four"
        initial={{ x: '200vw', opacity: 0 }}
        animate={{  x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      // transition={{ duration: 0.5 }}
      >
        <button onClick={handleBack} className={styles.backButton}>Voltar</button>
        <form onSubmit={(e) => handleSubmit(e)} className={styles.formStepFour}>
          <div className={styles.dateContainer}>
            <label>
              <span>Escolha um barbeiro</span>
              {professional !== '' &&
                barbers.map((e, i) => (
                  <div key={i} className={professional === e ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e} >{e}</div>
                ))
              }
              {professional === '' &&
                barbers.map((e, i) => (
                  <div key={i} className={i === activeIndex ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e} >{e}</div>
                ))}
            </label>
          </div>
          {/* <input type="submit" value='Agendar' /> */}
        </form>
      </motion.div>
    </AnimatePresence>
  )
}

export default StepFour