import { useEffect, useState } from 'react'
import styles from './StepFive.module.scss'
import { AnimatePresence, motion } from 'framer-motion';
const StepFive = ({
  step,
  setStep,
  exclusiveHours,
  hour,
  setHour,
  service,
  hours,
  appointmentHours,
  setFormError,
  setHoursIndex,
  setFormErrorMessage
}) => {

  const [activeIndex, setActiveIndex] = useState('');

  useEffect(() => {
    console.log('exclusiveHours')
    console.log(exclusiveHours)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (hour.length === 0) {
      setFormError(true)
      setFormErrorMessage('Escolha um horário')
    } else {
      setStep(6)
    }
  }

  const handleHourChange = (e, i) => {
    console.log(service)

    if (service === "Cabelo e Barba") {

      const hoursIndex = hours.indexOf(e) + 1;

      if (appointmentHours.includes(hours[hoursIndex])) {
        setHour('')
        setFormError(true);
        setFormErrorMessage("Horário seguinte indisponível. Este serviço consome 1h 40min.");
        return;
      } else {
        setHour(() => [
          e,
          hours[hoursIndex]
        ]);
        setActiveIndex(i)
        setHoursIndex(hoursIndex);
      }

    } else if (service === "Prótese + Corte") {

      const hoursIndex = [hours.indexOf(e.target.value) + 1, hours.indexOf(e) + 2]


      if (appointmentHours.includes(hours[hoursIndex[0]]) || appointmentHours.includes(hours[hoursIndex[1]])) {
        setHour('')
        setFormError(true);
        setFormErrorMessage("Horário seguinte indisponível. Este serviço consome 2h 30min.");
        return;
      } else {
        setHour(() => [
          e,
          hours[hoursIndex[0]],
          hours[hoursIndex[1]]
        ]);
        setActiveIndex(i)
        setHoursIndex(hoursIndex[0]);
      }

    } else if (service === "Manutenção da Prótese") {

      const hoursIndex = hours.indexOf(e) + 1;

      if (appointmentHours.includes(hours[hoursIndex])) {
        setHour('')
        setFormError(true);
        setFormErrorMessage("Horário seguinte indisponível. Este serviço consome 1h 40min.");
        return;
      } else {
        setHour(() => [
          e,
          hours[hoursIndex]
        ]);
        setActiveIndex(i)
        setHoursIndex(hoursIndex[0]);
      };

    } else {
      const hoursIndex = hours.indexOf(e) + 1;
      setActiveIndex(i)
      setHour(() => [e]);
      setHoursIndex(hoursIndex);
    }

  }

  const handleClick = (e, i) => {
    handleHourChange(e, i)
    // console.log('Hours')
    // console.log(e)
    // setActiveIndex(i)
    // setHour(e)
  }

  return (
    <AnimatePresence wait>
      <motion.div
        className={styles.container}
        key="step-five"
        initial={{ x: '200vw', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      // transition={{ duration: 0.5 }}
      >
        <button onClick={() => setStep(4)} className={styles.backButton}>Voltar</button>
        <form onSubmit={(e) => handleSubmit(e)} className={styles.formStepFive}>
          <div className={styles.dateContainer}>
            <label className={styles.label_flex}>
              <h3>Escolha um horário</h3>
              <div className={styles.hours}>
                {hour[0] !== '' &&
                  exclusiveHours.map((e, i) => (
                    <div key={i} className={hour[0] === e ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e} >{e}</div>
                  ))}
                {hour[0] === '' &&
                  exclusiveHours.map((e, i) => (
                    <div key={i} className={i === activeIndex ? styles.clicked : styles.dates} onClick={() => handleClick(e, i)} value={e} >{e}</div>
                  ))}
              </div >
            </label>
          </div>
          <input type="submit" value='Revisar' />
        </form>
      </motion.div>
    </AnimatePresence>
  )
}

export default StepFive