import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB66R0CQziZprSbsK1cce1suuewQ9p8fis",
  authDomain: "dev-agendabrb.firebaseapp.com",
  projectId: "dev-agendabrb",
  storageBucket: "dev-agendabrb.appspot.com",
  messagingSenderId: "176920534125",
  appId: "1:176920534125:web:4196452576e5502e107364"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);